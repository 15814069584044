import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  targetVesselIconLayer,
  targetVesselOutlineLayer,
  targetVesselPointLayer,
  targetVesselShapeLayer,
  targetVesselShapeLayer3D,
  targetVesselTextLayer,
} from '../layers-config';
import { getVesselFeatures } from '../utils/layer-utils';
import { type VesselPosition, type VesselViewModel } from '../../../types';
import { type MmsiKey } from '../../../../../global/types';
import { layerSources } from '../constants';
import { useMapContext } from '../../../../../global/state/GlobalProvider';

interface Props {
  zoomLevel: number;
  vesselData: Record<MmsiKey, VesselViewModel>;
  targetVesselPosition: VesselPosition;
}

const TargetVesselLayer: FC<Props> = ({ zoomLevel, vesselData, targetVesselPosition }) => {
  const { settings } = useMapContext();
  const data = useMemo(() => {
    if (!targetVesselPosition) {
      return null;
    }

    return {
      type: 'FeatureCollection',
      features: getVesselFeatures(targetVesselPosition, vesselData, true, settings, zoomLevel),
    };
  }, [targetVesselPosition, zoomLevel, vesselData]);

  return (
    <Source id={layerSources.targetVessel} type="geojson" data={data}>
      <Layer {...targetVesselIconLayer} />
      <Layer {...targetVesselShapeLayer} />
      <Layer {...targetVesselShapeLayer3D} />
      <Layer {...targetVesselPointLayer} />
      <Layer {...targetVesselOutlineLayer} />
      {settings?.showVesselNames ? <Layer {...targetVesselTextLayer} /> : null}
    </Source>
  );
};

export default TargetVesselLayer;
