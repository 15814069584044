import { type FC, useMemo, type Dispatch, type SetStateAction, useState } from 'react';
import { find, random } from 'lodash';
import ClusterLayer from './ClusterLayer';
import VesselsLayer from './VesselsLayer';
import FutureVesselTracksLayer from './FutureVesselTracksLayer';
import PastVesselTracksLayer from './PastVesselTracksLayer';
import JobTracksLayer from './JobTracksLayer';
import OperationsLayer from './OperationsLayer';
import TargetVesselLayer from './TargetVesselLayer';
import ActivityMarkersLayer from './ActivityMarkersLayer';
import QuiverPlotLayer from './QuiverPlotLayer';
import TidalDiamondLayer from './TidalDiamondLayer';
import NauticalLayer from './NauticalLayer';
import Map from '../index';
import { type Page, type MmsiKey } from '../../../../../global/types';
import { type Mode, type TargetVessel, type VesselPosition, type VesselViewModel } from '../../../types';
import { type Timestamp } from '../../../utils/timestamp';
import { type VesselTrackViewModel, type OperationViewModel, type VesselTracksViewModel } from '../types';
import { useMapOrchestration } from '../../../hooks/useMapOrchestration';
import { type Job } from '../../../../Trips/types';
import { useTidalData } from '../../../hooks/useTidalData';
import { useMapContext } from '../../../../../global/state/GlobalProvider';
import { mapCenters } from '../constants';

export interface Props {
  vesselPositions: VesselPosition[];
  vesselData: Record<MmsiKey, VesselViewModel>;
  operation: OperationViewModel;
  targetVesselTracks: VesselTracksViewModel;
  targetVessel: TargetVessel;
  onTargetVesselChange: (mmsi: string) => void;
  timestamp: Timestamp;
  setLockedOnTarget: Dispatch<SetStateAction<boolean>>;
  page: Page;
  operations: OperationViewModel[];
  onOperationSelect: (value: string) => void;
  isWorldView: boolean;
  isTripsPanelOpen: boolean;
  pageMode: Mode;
  hoveredJob: Job;
  timezone: string;
  hoveredJobVesselTracks: VesselTrackViewModel[];
}

const MapMode: FC<Props> = ({
  vesselPositions,
  vesselData,
  targetVesselTracks,
  targetVessel,
  onTargetVesselChange,
  operation,
  timestamp,
  setLockedOnTarget,
  page,
  operations,
  onOperationSelect,
  isWorldView,
  isTripsPanelOpen,
  pageMode,
  hoveredJob,
  timezone,
  hoveredJobVesselTracks,
}) => {
  const mapOrchestrator = useMapOrchestration();
  const { zoomLevel } = mapOrchestrator;
  const {
    settings: { showQuiverPlot, showNauticalMap },
  } = useMapContext();
  const { data: tidalData } = useTidalData(operation?.key, timestamp);
  const [activeTidalDiamond, setActiveTidalDiamond] = useState<string>(null);

  const targetVesselPosition = useMemo(() => {
    return targetVessel.mmsi && find(vesselPositions, p => p.mmsi === targetVessel.mmsi);
  }, [vesselPositions, targetVessel.mmsi]);

  const initialMapCenter = useMemo(() => {
    if (isWorldView) {
      const index = random(0, mapCenters.length - 1, false);
      return mapCenters[index];
    }

    return operation?.location;
  }, [isWorldView, operation]);

  return (
    <Map
      vesselPositions={vesselPositions}
      onTargetVesselChange={onTargetVesselChange}
      initialMapCenter={initialMapCenter}
      setLockedOnTarget={setLockedOnTarget}
      targetVessel={targetVessel}
      targetVesselPosition={targetVesselPosition}
      timestamp={timestamp}
      operation={operation?.name}
      page={page}
      onOperationSelect={onOperationSelect}
      isWorldView={isWorldView}
      isTripsPanelOpen={isTripsPanelOpen}
      pageMode={pageMode}
      mapOrchestrator={mapOrchestrator}
      setActiveTidalDiamond={setActiveTidalDiamond}
      timezone={timezone}
    >
      {/* Layer order affects rendering (i.e. last layer is rendered on top of previous) */}
      {!isWorldView && (
        <>
          {showNauticalMap && <NauticalLayer />}
          <FutureVesselTracksLayer
            vesselTracks={targetVesselTracks?.futureTracks}
            activities={targetVesselTracks?.activities}
          />
          <PastVesselTracksLayer
            vesselTracks={targetVesselTracks?.pastTracks}
            activities={targetVesselTracks?.activities}
          />
          <ActivityMarkersLayer activities={targetVesselTracks?.activities} />
          <JobTracksLayer job={hoveredJob} vesselTracks={hoveredJobVesselTracks} />
          <VesselsLayer
            zoomLevel={zoomLevel}
            vesselData={vesselData}
            vesselPositions={vesselPositions}
            targetVesselMmsi={targetVessel.mmsi}
          />
          <TargetVesselLayer
            zoomLevel={zoomLevel}
            vesselData={vesselData}
            targetVesselPosition={targetVesselPosition}
          />
          {showQuiverPlot && (
            <>
              <QuiverPlotLayer data={tidalData?.quiverPlotArrow} />
              <TidalDiamondLayer data={tidalData?.tidalDiamond} activeTidalDiamond={activeTidalDiamond} />
            </>
          )}
        </>
      )}
      <ClusterLayer zoomLevel={zoomLevel} vesselData={vesselData} vesselPositions={vesselPositions} />
      <OperationsLayer operations={operations} currentOperation={operation?.name} />
    </Map>
  );
};

export default MapMode;
