import { useState, type FC } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Text,
  useDisclosure,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';
import { ThemeButton } from './utils/CreateThemeButton';
import { InformationPanelAction } from '../../types';
import { ReactComponent as GreenIncorrectInformationIcon } from '../../../../static/svg/GreenIncorrectInformationIcon.svg';
import { translate } from '../../../../global/translation';
import { Event, type Page } from '../../../../global/types';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import ConfirmFeedbackCard, { ConfirmCardSource } from '../../../../global/components/ConfirmFeedbackCard';
import { type Timestamp } from '../../utils/timestamp';

interface IncorrectInformationProps {
  page: Page;
  mmsi: string;
  shipName: string;
  timestamp: Timestamp;
  timezone: string;
}

const IncorrectInformation: FC<IncorrectInformationProps> = ({ page, mmsi, shipName, timestamp, timezone }) => {
  const listOfThemes = [
    translate('THEME_1'),
    translate('THEME_2'),
    translate('THEME_3'),
    translate('THEME_4'),
    translate('THEME_5'),
    translate('THEME_6'),
    translate('THEME_7'),
  ];
  const [selectedButtons, setSelectedButtons] = useState<string[]>([]);
  const [textArea, setTextArea] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const appInsights = useAppInsights();

  const onOpen = (): void => {
    setIsModalOpen(true);
    openModal();
  };

  const onClose = (operation: InformationPanelAction): void => {
    submitIncorrectInformation(operation);
    setSelectedButtons([]);
    closeModal();
    setIsModalOpen(false);
    setTextArea('');
    if (operation === InformationPanelAction.Submit) {
      setOpenConfirmModal(true);
    }
  };

  const handleButtonClick = (value: string): void => {
    if (!selectedButtons.includes(value)) {
      setSelectedButtons(prev => [...prev, value]);
    } else {
      setSelectedButtons(prev => prev.filter(item => item !== value));
    }
  };

  function submitIncorrectInformation(operation: InformationPanelAction): void {
    const dateString = timezone && timestamp ? timestamp.toLocationDateTimeOffset(timezone).dateString : '';

    const event = getEventFactory(Event.WrongInformation)(
      page,
      mmsi,
      shipName,
      dateString,
      selectedButtons,
      textArea,
      encodeURIComponent(window.location.href),
      operation
    );
    appInsights.trackAnalyticsEvent(event);
  }

  const { isOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();

  return (
    <>
      <Tooltip placement="bottom-start" label={translate('MODAL_BTN')}>
        <IconButton
          variant="secondary"
          icon={<ChatIcon boxSize={4} />}
          aria-label={translate('MODAL_BTN')}
          onClick={onOpen}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose(InformationPanelAction.Cancel);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <GreenIncorrectInformationIcon className="modal-icon" />
          <ModalHeader>{translate('MODAL_HEADING')}</ModalHeader>
          <ModalCloseButton className="modal-close-btn" />
          <ModalBody className="incorrect-information-modal">
            <Text>{translate('MODAL_SUB_HEADING')}</Text>
            <div className="theme-button-area">
              {listOfThemes.map((x, i) => (
                <ThemeButton
                  key={`theme-button-${i}-${x}`}
                  text={x}
                  onButtonClick={handleButtonClick}
                  isModalOpen={isModalOpen}
                />
              ))}
            </div>
            <Text>{translate('MODAL_TEXTAREA_DESCRIPTION')}</Text>
            <Textarea
              placeholder={translate('MODAL_TEXTAREA')}
              value={textArea}
              onChange={e => {
                setTextArea(e.target.value);
              }}
              aria-label="description"
              size="md"
            />
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              variant="secondary"
              onClick={() => {
                onClose(InformationPanelAction.Cancel);
              }}
              float="left"
              width="48%"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              isDisabled={selectedButtons.length < 1}
              onClick={() => {
                onClose(InformationPanelAction.Submit);
              }}
              padding-left="5px"
              width="48%"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ConfirmFeedbackCard
        isOpen={openConfirmModal}
        source={ConfirmCardSource.SeeSomethingWrongReport}
        onClose={() => {
          setOpenConfirmModal(false);
        }}
      />
    </>
  );
};
export default IncorrectInformation;
