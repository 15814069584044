import { useMemo, useState } from 'react';
import useQueryParams from './useQueryParams';
import { type MmsiKey } from '../../../global/types';
import type { TargetVessel, TargetVesselHandler, VesselViewModel } from '../types';

export const useTargetVessel = (vesselData: Record<MmsiKey, VesselViewModel>): TargetVesselHandler => {
  const { mmsi } = useQueryParams();
  const [isLockedOnTarget, setIsLockedOnTarget] = useState(!!mmsi);
  const [targetVesselMmsi, setTargetVesselMmsi] = useState(mmsi);

  const targetVessel = useMemo((): TargetVessel => {
    if (!targetVesselMmsi) {
      return { mmsi: null, isLockedOnTarget: null } as TargetVessel;
    }

    const newTargetVessel = vesselData[targetVesselMmsi];
    if (newTargetVessel) {
      return { ...newTargetVessel, isLockedOnTarget };
    }
    return { mmsi: targetVesselMmsi, isLockedOnTarget } as TargetVessel;
  }, [vesselData, targetVesselMmsi, isLockedOnTarget]);

  return { targetVessel, setTargetVesselMmsi, setIsLockedOnTarget };
};
