import { useQuery } from 'react-query';
import { type VesselTrackViewModel } from '../components/Map/types';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import STATUS from '../../../global/statusCodes';
import { Timestamp } from '../utils/timestamp';

const useHoveredVesselTracks = (tripId: string, jobId: string): VesselTrackViewModel[] => {
  const fetchVesselTracks = async (): Promise<VesselTrackViewModel[]> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<VesselTrackViewModel[]>(
      `/trips/${tripId}/jobs/${jobId}/vessel-tracks`
    );

    return response && response.status === STATUS.OK ? response.data : [];
  };

  return useQuery({
    queryKey: ['vesselTracks', tripId, jobId],
    queryFn: fetchVesselTracks,
    staleTime: Timestamp.fromHours(1).toMilliseconds(),
    cacheTime: Timestamp.fromHours(1).toMilliseconds(),
    enabled: !!tripId && !!jobId,
  }).data;
};

export default useHoveredVesselTracks;
