import { type FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { some } from 'lodash';
import MapMode from './components/Map/components/MapMode';
import BlurMap from './components/mapFeedback/BlurMap';
import Scrubber from './components/Scrubber';
import TripsPanel from './components/TripsPanel';
import { Mode } from './types';
import { Timestamp } from './utils/timestamp';
import useQueryParams from './hooks/useQueryParams';
import { approximateTimestamp } from './utils/approximateTimestamp';
import { useClearCachedData } from './hooks/useClearCachedData';
import { useTargetVesselTracks } from './hooks/useTargetVesselTracks';
import { LeftSideControls } from './components/LeftSideControls';
import { useTripsPanelStateManager } from './hooks/useTripsPanelStateManager';
import NoDataMessage from './components/NoDataMessage';
import { useTargetVessel } from './hooks/useTargetVessel';
import QuickAccessPanel from './components/Map/components/QuickAccess';
import useHoveredVesselTracks from './hooks/useHoveredVesselTracks';
import ChartSettings from './components/Map/components/ChartSettings';
import { useManageTimestamps } from './hooks/useManageTimestamps';
import { useTrackPageView } from './hooks/useTrackPageView';
import { useMapData } from './hooks/useMapData';
import { type OperationViewModel } from './components/Map/types';
import { useModeSwitches } from './hooks/useModeSwitches';
import RateAppFlow from '../../global/components/RateAppFlow';
import { Event, Feature, Page, VesselTargetSource } from '../../global/types';
import { useDispatch, useTripsContext } from '../../global/state/GlobalProvider';
import { useAppInsights } from '../../hooks/useAppInsights';
import { getEventFactory } from '../../global/utils/get-event-factory';
import { useShallowNavigate } from '../../hooks/useShallowNavigate';
import { useDetectMobileScreenWidth } from '../../hooks/useDetectMobileScreenWidth';
import { TripsPanelState, type Filters, type TripJobPair } from '../Trips/types';
import { useAppContext } from '../../global/components/AppContextProvider';
import { defaultFilters } from '../Trips/utils/initial-trips-state';
import GlobalExceptionHandlerModal from '../../global/components/GlobalExceptionHandlerModal';
import { ActionType } from '../../global/state/reducer';

const App: FC = () => {
  const { timestamp } = useQueryParams();
  const { port } = useParams();
  const { mode } = useAppContext();
  const { filters, operations, tripsPanelState } = useTripsContext();

  const { playbackTimestamp, currentTimestamp, handleSetPlaybackTimestamp } = useManageTimestamps(timestamp, mode);
  const [jobStartTimestamp, setJobStartTimestamp] = useState<Timestamp>(null);

  const dispatch = useDispatch();
  const shallowNavigate = useShallowNavigate();
  useClearCachedData(mode);
  const appInsights = useAppInsights();

  const [operation, operationKey] = useMemo<[OperationViewModel, string]>(() => {
    if (operations?.length && port) {
      const operation = operations.find(
        o => o.key.toLowerCase() === port.toLowerCase() || o.name.toLowerCase() === port.toLowerCase()
      );
      return [operation, operation?.key ?? port];
    }

    return [null, port || filters.port];
  }, [port, operations, filters?.port]);

  const isWorldView = useMemo(() => {
    if (!operations?.length) {
      return !operationKey;
    }
    return !some(operations, o => o.key === operationKey);
  }, [operationKey, operations]);

  const [tripJobPair, setTripJobPair] = useState<TripJobPair>(null);
  const { job: hoveredJob, trip } = tripJobPair || {};
  const hoveredJobVesselTracks = useHoveredVesselTracks(trip?.tripId, hoveredJob?.jobId);
  const [isDraggingScrubber, setIsDraggingScrubber] = useState(false);
  const isMobileView = useDetectMobileScreenWidth();

  useTrackPageView(mode, isWorldView, operation, operationKey);

  const handleSetJobStartTimestamp = useCallback((timestamp: Timestamp): void => {
    const approximatedTimestamp = approximateTimestamp(timestamp);
    setJobStartTimestamp(approximatedTimestamp);
  }, []);

  const isTripsPanelOpened = useMemo((): boolean => {
    if (isWorldView) {
      return false;
    }

    return tripsPanelState ? tripsPanelState === TripsPanelState.Open : !isMobileView;
  }, [tripsPanelState, isMobileView, isWorldView]);

  const page = useMemo(() => {
    if (mode === Mode.Playback) {
      return Page.Playback;
    }

    return Page.Liveview;
  }, [mode]);

  const tripsPanelStateManager = useTripsPanelStateManager(page, isMobileView, operation?.name ?? operationKey);

  const { switchToLiveview, switchToPlayback } = useModeSwitches(
    tripsPanelState,
    tripsPanelStateManager,
    isMobileView,
    handleSetPlaybackTimestamp
  );
  const { liveData, vesselPositions, vesselData, isPlaybackDataLoading, isError } = useMapData(
    operationKey,
    mode,
    playbackTimestamp
  );

  const isLoading = useMemo(
    () => (mode === Mode.Liveview && !liveData) || !operations?.length,
    [liveData, mode, operations]
  );

  const { targetVessel, setTargetVesselMmsi, setIsLockedOnTarget } = useTargetVessel(vesselData);

  const isMapVisible = useMemo(() => {
    return !!operations?.length && (!operationKey || !isLoading);
  }, [isLoading, operationKey, operations]);

  const isScrubberVisible = useMemo(() => {
    return mode === Mode.Playback && operation?.timezone && !!playbackTimestamp;
  }, [mode, operation]);

  const targetVesselTracks = useTargetVesselTracks(
    targetVessel,
    playbackTimestamp,
    isDraggingScrubber,
    operationKey,
    liveData
  );

  const handleOperationSelect = useCallback(
    (value: string): void => {
      const { endDate, startDate, vesselName } = defaultFilters;
      const newFilter: Filters = {
        ...filters,
        endDate,
        startDate,
        vesselName,
        port: value,
      };
      dispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
      setTargetVesselMmsi(null);
      shallowNavigate({
        port: value,
        mmsi: targetVessel?.mmsi,
        timestamp: playbackTimestamp,
      });
      appInsights.trackAnalyticsEvent(getEventFactory(Event.OperationSelected)(page, value));
    },
    [filters, targetVessel?.mmsi, playbackTimestamp]
  );

  const handleMapInit = (isError: boolean, page: Page): JSX.Element => {
    if (isError) {
      return <GlobalExceptionHandlerModal errorMessage="An error occurred on map init" page={page} />;
    }
    return <BlurMap />;
  };

  const handleTargetVesselChange = useCallback(
    (mmsi: string = null) => {
      if (mmsi) {
        setIsLockedOnTarget(true);
      } else {
        setIsLockedOnTarget(false);
      }

      if (mmsi !== targetVessel?.mmsi && mmsi) {
        setTargetVesselMmsi(mmsi);
      }
    },
    [targetVessel]
  );

  useLayoutEffect(() => {
    if (isDraggingScrubber) {
      return;
    }
    shallowNavigate({
      port: operationKey,
      mmsi: targetVessel?.mmsi,
      timestamp: playbackTimestamp,
    });
  }, [playbackTimestamp, targetVessel?.mmsi, isDraggingScrubber]);

  useEffect(() => {
    if (!!port && filters.port !== port) {
      dispatch({
        type: ActionType.UPDATE_FILTER,
        payload: {
          ...filters,
          port,
        },
      });
    }
  }, [port]);

  useEffect(() => {
    if (timestamp && !jobStartTimestamp) {
      handleSetJobStartTimestamp(Timestamp.fromSeconds(Number(timestamp)));
    }

    if (timestamp && mode !== Mode.Playback) {
      switchToPlayback();
      return;
    }

    if (!timestamp && mode !== Mode.Liveview) {
      switchToLiveview();
    }
  }, [timestamp]);

  useEffect(() => {
    if (!targetVessel) {
      return;
    }

    const event = targetVessel.isLockedOnTarget
      ? getEventFactory(Event.VesselTargetted)(
          page,
          targetVessel.name,
          targetVessel.mmsi,
          targetVessel.type,
          window.location.href,
          currentTimestamp,
          VesselTargetSource.ClickOnRecenterButton
        )
      : getEventFactory(Event.VesselUntargeted)(
          page,
          targetVessel.name,
          targetVessel.mmsi,
          targetVessel.type,
          window.location.href,
          currentTimestamp
        );
    appInsights.trackAnalyticsEvent(event);
  }, [targetVessel]);

  return (
    <Flex flexDirection="row">
      <RateAppFlow parent={Feature.Map} page={page} />
      {!!operation && (
        <TripsPanel
          isOpen={isTripsPanelOpened}
          onClose={tripsPanelStateManager.close}
          setStartTimestamp={handleSetJobStartTimestamp}
          setTimestamp={handleSetPlaybackTimestamp}
          page={page}
          onTargetVesselChange={handleTargetVesselChange}
          setHoveredJob={setTripJobPair}
          operation={operation}
        />
      )}
      <Box className="pb-page-background">
        <LeftSideControls
          isTripsPanelOpened={isTripsPanelOpened}
          liveData={liveData}
          mode={mode}
          onOperationSelect={handleOperationSelect}
          operation={operation}
          operationKey={operationKey}
          isWorldView={isWorldView}
          toggleTripsPanel={tripsPanelStateManager.toggle}
        />
        <NoDataMessage isShown={!isWorldView && !(isLoading || isPlaybackDataLoading) && !vesselPositions?.length} />
        {isMapVisible ? (
          <>
            <MapMode
              vesselPositions={vesselPositions ?? []}
              vesselData={vesselData}
              setLockedOnTarget={setIsLockedOnTarget}
              timestamp={currentTimestamp}
              targetVessel={targetVessel}
              onTargetVesselChange={handleTargetVesselChange}
              operation={operation}
              targetVesselTracks={targetVesselTracks}
              page={page}
              operations={operations}
              onOperationSelect={handleOperationSelect}
              isWorldView={isWorldView}
              isTripsPanelOpen={isTripsPanelOpened}
              pageMode={mode}
              hoveredJob={hoveredJob}
              timezone={operation?.timezone}
              hoveredJobVesselTracks={hoveredJobVesselTracks}
            />
            {!isWorldView && (
              <>
                <QuickAccessPanel
                  operation={operation?.name ?? operationKey}
                  timestamp={currentTimestamp}
                  vesselData={vesselData}
                  onTargetVesselChange={handleTargetVesselChange}
                  targetVessel={targetVessel}
                  vesselPositions={vesselPositions}
                  setTimestamp={handleSetPlaybackTimestamp}
                  setStartTimestamp={setJobStartTimestamp}
                  page={page}
                  timezone={operation?.timezone}
                  startTimestamp={jobStartTimestamp}
                />
                <ChartSettings page={page} operation={operation?.name ?? operationKey} />
              </>
            )}
          </>
        ) : (
          handleMapInit(isError, page)
        )}

        {isScrubberVisible && (
          <Scrubber
            timestamp={playbackTimestamp}
            setTimestamp={handleSetPlaybackTimestamp}
            targetVessel={targetVessel}
            vesselPositions={vesselPositions}
            isTripsPanelOpen={isTripsPanelOpened}
            isDraggingScrubber={isDraggingScrubber}
            setIsDraggingScrubber={setIsDraggingScrubber}
            timezone={operation.timezone}
            switchToLiveview={switchToLiveview}
            onTargetLock={handleTargetVesselChange}
          />
        )}
      </Box>
    </Flex>
  );
};
export default App;
