import { type FC, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { first } from 'lodash';
import { DatePicker } from 'rsuite';
import moment from 'moment-timezone';
import { addMinutes, addSeconds, isBefore } from 'date-fns';
import { type TypeAttributes } from 'rsuite/esm/internals/types';
import { ModalsEvent, type ModalState } from '../types';
import ActionButton from '../ActionButton';
import { ReactComponent as ChevronLeftIcon } from '../../../../../../../static/svg/ChevronLeft.svg';
import { ReactComponent as CalendarIcon } from '../../../../../../../static/svg/CalendarIcon.svg';
import { ReactComponent as ClockIcon } from '../../../../../../../static/svg/ClockIcon.svg';
import { ReactComponent as GoBack1hIcon } from '../../../../../../../static/svg/GoBack1hIcon.svg';
import { ReactComponent as GoBack2hIcon } from '../../../../../../../static/svg/GoBack2hIcon.svg';
import { ReactComponent as GoBack8hIcon } from '../../../../../../../static/svg/GoBack8hIcon.svg';
import { translate } from '../../../../../../../global/translation';
import { Timestamp } from '../../../../../utils/timestamp';
import { Event, type Page } from '../../../../../../../global/types';
import { useAppInsights } from '../../../../../../../hooks/useAppInsights';
import { getEventFactory } from '../../../../../../../global/utils/get-event-factory';
import { useDetectMobileScreenWidth } from '../../../../../../../hooks/useDetectMobileScreenWidth';
import { getLocalTimestampFromDate } from '../../../../../../../global/utils/date-utils';

export interface Props {
  modalState: ModalState;
  operation: string;
  selectedVesselMmsi: string;
  dispatch: (event: ModalsEvent) => void;
  locatedAtBottom: boolean;
  timestamp: Timestamp;
  setTimestamp: (timestamp: Timestamp) => void;
  setStartTimestamp: (timestamp: Timestamp) => void;
  page: Page;
  timezone: string;
}

const PickTime: FC<Props> = ({
  modalState,
  operation,
  selectedVesselMmsi,
  dispatch,
  locatedAtBottom,
  timestamp,
  setTimestamp,
  setStartTimestamp,
  page,
  timezone,
}): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const containerRef = useRef<HTMLElement>();
  const isMobileScreenWidth = useDetectMobileScreenWidth();
  const isLowResolutionDesktop = first(useMediaQuery('(max-height: 610px)'));
  const appInsights = useAppInsights();

  const handleBack = (): void => {
    resetInput();
    const event = getEventFactory(Event.BackFromPickTimeClicked)(page, operation, Timestamp.now());
    appInsights.trackAnalyticsEvent(event);
    dispatch(ModalsEvent.BackFromPickTime);
  };

  const handleDateTimeChange = (date: Date): void => {
    if (!date) {
      setSelectedDate(null);
      return;
    }

    date = getCorrectDate(date);
    setSelectedDate(date);
  };

  const getLocalOperationDate = (date: Date): Date => {
    const utcDate = addMinutes(date, date.getTimezoneOffset());
    const offset = moment.tz.zone(timezone).utcOffset(date.valueOf());
    const localTimeOffset = Timestamp.fromMinutes(-offset);
    return addSeconds(utcDate, localTimeOffset.toSeconds());
  };

  const getCorrectDate = (date: Date): Date => {
    const operationCurrentTime = getLocalOperationDate(new Date());
    return isBefore(operationCurrentTime, date) ? operationCurrentTime : date;
  };

  const shouldDisableDate = (date: Date): boolean => {
    const midnightDate = new Date(date);
    midnightDate.setHours(0, 0, 0);
    const operationCurrentTime = getLocalOperationDate(new Date());

    return isBefore(operationCurrentTime, midnightDate);
  };

  const resetInput = (): void => {
    setSelectedDate(null);
  };

  const switchToPlayback = (timestamp: Timestamp): void => {
    const event = getEventFactory(Event.RedirectedToPlaybackFromPickTime)(
      page,
      operation,
      selectedVesselMmsi,
      timestamp
    );
    appInsights.trackAnalyticsEvent(event);
    setTimestamp(timestamp);
    setStartTimestamp(timestamp);
  };

  const handleGoClick = (): void => {
    const timestamp = getLocalTimestampFromDate(selectedDate, timezone);
    switchToPlayback(timestamp);
  };

  const handleQuickReplayClick = (hoursAgo: number): void => {
    const timeToSubtract = Timestamp.fromHours(hoursAgo);

    switchToPlayback((timestamp || Timestamp.now()).subtract(timeToSubtract));
  };

  const renderCalendarIcon = (): JSX.Element => {
    return <CalendarIcon />;
  };

  const renderClockIcon = (): JSX.Element => {
    return <ClockIcon />;
  };

  const datePickerPlacement = useMemo<TypeAttributes.Placement>(() => {
    if (isMobileScreenWidth && locatedAtBottom) {
      return 'bottomStart';
    }
    if (isMobileScreenWidth && !locatedAtBottom) {
      return 'topStart';
    }

    if (isLowResolutionDesktop) {
      return 'left';
    }

    return 'bottomStart';
  }, [isMobileScreenWidth, isLowResolutionDesktop]);

  const timePickerPlacement = useMemo<TypeAttributes.Placement>(() => {
    if (isMobileScreenWidth && locatedAtBottom) {
      return 'bottom';
    }
    if (isMobileScreenWidth && !locatedAtBottom) {
      return 'top';
    }

    if (isLowResolutionDesktop) {
      return 'left';
    }

    return 'bottom';
  }, [isMobileScreenWidth, isLowResolutionDesktop]);

  return (
    <Popover isOpen={modalState.isOpen} isLazy>
      <PopoverContent className={`${locatedAtBottom ? '' : 'pick-time-live-view'} pick-time`} ref={containerRef}>
        <PopoverBody>
          <Flex alignItems="flex-start" flexDirection="column">
            <ActionButton
              icon={<ChevronLeftIcon style={{ display: 'inline-block' }} />}
              labelKey="BACK"
              onClick={handleBack}
            />
            <Text as="b" className="quick-access-panel-header">
              {translate('PICK_TIME')}
            </Text>
            <Text as="p" className="quick-access-panel-text" textAlign="left">
              {translate('PICK_TIME_DESCRIPTION')}
            </Text>
            <HStack spacing="12px" width="100%">
              <Box className="date-picker-container">
                <DatePicker
                  className="pick-time-date-picker"
                  menuClassName="pick-time-date-picker-menu"
                  format="yyyy-MM-dd"
                  placement={datePickerPlacement}
                  shouldDisableDate={shouldDisableDate}
                  editable
                  cleanable={false}
                  value={selectedDate}
                  caretAs={renderCalendarIcon}
                  onChange={handleDateTimeChange}
                  oneTap
                />
              </Box>
              <Box className="time-picker-container">
                <DatePicker
                  className="pick-time-date-picker"
                  menuClassName="pick-time-date-picker-menu"
                  format="HH:mm"
                  placeholder="00:00"
                  placement={timePickerPlacement}
                  editable
                  cleanable={false}
                  value={selectedDate}
                  caretAs={renderClockIcon}
                  onChange={handleDateTimeChange}
                />
              </Box>
              <Button className="go-button" variant="primary" isDisabled={!selectedDate} onClick={handleGoClick}>
                {translate('GO')}
              </Button>
            </HStack>
            <HStack width="100%" spacing="12px" marginTop="12px">
              <IconButton
                variant="secondary"
                icon={<GoBack1hIcon />}
                aria-label={translate('REPLAY_1H_AGO')}
                onClick={() => {
                  handleQuickReplayClick(1);
                }}
              />
              <IconButton
                variant="secondary"
                icon={<GoBack2hIcon />}
                aria-label={translate('REPLAY_2H_AGO')}
                onClick={() => {
                  handleQuickReplayClick(2);
                }}
              />
              <IconButton
                variant="secondary"
                icon={<GoBack8hIcon />}
                aria-label={translate('REPLAY_8H_AGO')}
                onClick={() => {
                  handleQuickReplayClick(8);
                }}
              />
            </HStack>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PickTime;
