import { type Popup } from 'mapbox-gl';
import { type Timer } from './utils/timer';
import { type VesselFeatureProperties } from './components/Map/types';
import { type Timestamp } from './utils/timestamp';
import {
  type Location,
  type ActivityType,
  type MmsiKey,
  type Speed,
  type TimestampKey,
  type TimestampModelRange,
  type TimestampModel,
  type AnalyticsEvent,
} from '../../global/types';

export enum InformationPanelAction {
  Submit,
  Cancel,
}

export interface PlaybackViewModel {
  timestampRange: TimestampModelRange;
  vesselData: Record<MmsiKey, VesselViewModel>;
  vesselPositions: Record<TimestampKey, VesselPosition[]>;
  scrubberData: Record<MmsiKey, ScrubberViewModel>;
}

export interface LiveDataViewModel {
  vesselData: Record<MmsiKey, VesselViewModel>;
  vesselPositions: VesselPosition[];
}

export interface ScrubberViewModel {
  activities: ActivityViewModel[];
  helmEvents: HelmEvent[];
}

export interface BaseActivity {
  activityId: string;
  timestampRange: TimestampModelRange;
  type: ActivityType;
  isSpeedLimited: boolean;
}

export interface ActivityViewModel extends BaseActivity {
  speedChartData: SpeedChartEntry[];
  isTugActivity: boolean;
}

export interface HelmEvent {
  id: string;
  mmsi: string;
  type: HelmEventType;
  timestamp: TimestampModel;
  operation: string;
  assistedVesselName: string;
}

export interface HelmEventMarkerData extends Omit<HelmEvent, 'operation'> {
  offset: number;
}

export enum HelmEventType {
  RequestedTime = 'RequestedTime',
  Mobilization = 'Mobilization',
  Demobilisation = 'Demobilization',
  Sailing = 'Sailing',
  Berthing = 'Berthing',
  Waiting = 'Waiting',
  Shifting = 'Shifting',
  Escorting = 'Escorting',
  Working = 'Working',
}

export interface VesselViewModel {
  mmsi: string;
  imo: string;
  name: string;
  description: string;
  type: string;
  dimensions: VesselDimensions;
}

export interface VesselPosition {
  mmsi: string;
  status?: number;
  location: Location;
  timestamp: number;
  heading: number;
  speed: Speed;
  tidalSpeed?: number;
  tidalDirection?: number;
  positionAgeInMinutes: number;
}

export interface VesselDimensions {
  toBow: number;
  toStern: number;
  toStarboard: number;
  toPort: number;
}

export interface SpeedChartEntry {
  timestamp: number;
  speed?: number;
}

export interface ClockData {
  readonly time: string;
  readonly timezone: string;
  readonly dateString?: string;
}

export interface TooltipRef {
  popup: Popup;
  timer: Timer;
  vesselProperties: VesselFeatureProperties;
  eventOnCloseFactory: (tooltip: React.MutableRefObject<TooltipRef>) => AnalyticsEvent;
}

export enum OperationLiveStatus {
  Live = 'Live',
  Delayed = 'Delayed',
  Unavailable = 'Unavailable',
}

export enum Mode {
  Liveview = 'Liveview',
  Playback = 'Playback',
}

export interface ActivityOffset {
  fromPosition: number;
  width: number;
}

export interface EcoRatingTooltip {
  isOpen: boolean;
  isDismissible: boolean;
  onHover: () => void;
  onHoverOut: () => void;
  onTap: () => void;
  onDismiss: () => void;
  onClose: () => void;
}

export interface TargetVessel extends VesselViewModel {
  isLockedOnTarget: boolean;
}

export interface MapData {
  liveData: LiveDataViewModel;
  vesselPositions: VesselPosition[];
  vesselData: Record<string, VesselViewModel>;
  isPlaybackDataLoading: boolean;
  isError: boolean;
}

export interface TimestampHandler {
  playbackTimestamp: Timestamp;
  currentTimestamp: Timestamp;
  handleSetPlaybackTimestamp: (timestamp: Timestamp) => void;
}

export interface ModeSwitchHandler {
  switchToLiveview: () => void;
  switchToPlayback: () => void;
}

export interface TargetVesselHandler {
  targetVessel: TargetVessel;
  setTargetVesselMmsi: (mmsi: string) => void;
  setIsLockedOnTarget: (isLockedOnTarget: boolean) => void;
}
