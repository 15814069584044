import { type FC } from 'react';
import cx from 'classnames';
import { IconButton, PopoverTrigger } from '@chakra-ui/react';
import QuickAccessHeaderSelected from './QuickAccessHeaderSelected';
import { ReactComponent as ExpandIcon } from '../../../../../../static/svg/Expand.svg';
import { Mode, type TargetVessel } from '../../../../types';
import { useAppContext } from '../../../../../../global/components/AppContextProvider';
import { translate } from '../../../../../../global/translation';

interface Props {
  targetVessel: TargetVessel;
  formattedSpeed: string;
  speedType: string;
  handleQuickAccessOpen: () => void;
  hidden: boolean;
  toolTipText: string;
  className: string;
  onTargetVesselChange: (mmsi: string) => void;
}

const Trigger: FC<Props> = ({
  targetVessel,
  formattedSpeed,
  speedType,
  handleQuickAccessOpen,
  hidden,
  toolTipText,
  className,
  onTargetVesselChange,
}): JSX.Element => {
  const { mode } = useAppContext();

  const noVesselTriggerClasses = cx({
    'quick-access-panel-trigger-playback': mode === Mode.Playback,
    'quick-access-panel-trigger-live-view': mode === Mode.Liveview,
  });

  return (
    <span hidden={hidden}>
      {targetVessel.mmsi && mode === Mode.Liveview ? (
        <PopoverTrigger>
          <QuickAccessHeaderSelected
            targetVessel={targetVessel}
            formattedSpeed={formattedSpeed}
            speedType={speedType}
            handleQuickOperation={handleQuickAccessOpen}
            className={className}
            toolTipText={toolTipText}
            isCollapsed={false}
            onTargetLock={onTargetVesselChange}
          />
        </PopoverTrigger>
      ) : (
        <PopoverTrigger>
          <div>
            <IconButton
              variant="secondary"
              className={noVesselTriggerClasses}
              onClick={handleQuickAccessOpen}
              icon={<ExpandIcon />}
              aria-label={translate('COLLAPSE_ARIA_DESCRIPTION')}
            />
          </div>
        </PopoverTrigger>
      )}
    </span>
  );
};

export default Trigger;
