import { useEffect } from 'react';
import { useInfiniteQuery, type UseInfiniteQueryResult } from 'react-query';
import { ApiClient } from '../../../api/api-client';
import STATUS from '../../../global/statusCodes';
import { type Filters, type TripsPanelViewModel } from '../../Trips/types';
import { convertFilterToQuery } from '../../Trips/utils/convertFilterToQuery';
import { getAuthClient } from '../../../global/utils/authUtils';
import { Timestamp } from '../utils/timestamp';
import { useAppInsights } from '../../../hooks/useAppInsights';
import { getEventFactory } from '../../../global/utils/get-event-factory';
import { useTripsContext } from '../../../global/state/GlobalProvider';
import { type Page, Event, Feature } from '../../../global/types';
import { defaultTripsPageNumber, quantityOfTripsPerRequest } from '../components/TripsPanel/constants';

export const useTripsInfiniteQuery = (
  filters: Filters,
  limit: number,
  currentPage: number,
  page: Page
): UseInfiniteQueryResult<TripsPanelViewModel> => {
  const queryString = convertFilterToQuery(filters);
  const { previousFilters } = useTripsContext();
  const appInsights = useAppInsights();
  const fetchProjects = async ({ pageParam = defaultTripsPageNumber }): Promise<TripsPanelViewModel> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<TripsPanelViewModel>(
      `/trips/paginated/${pageParam}/${limit}?${queryString}`
    );

    return response && response.status === STATUS.OK
      ? response.data
      : ({
          trips: [],
          paging: {
            hasNextPage: false,
            page: defaultTripsPageNumber,
            size: quantityOfTripsPerRequest,
            total: 0,
          },
        } as TripsPanelViewModel);
  };

  const result = useInfiniteQuery<TripsPanelViewModel>({
    queryKey: ['tripsPanelByPage', queryString, currentPage, limit],
    queryFn: fetchProjects,
    getNextPageParam: lastPage => {
      return lastPage?.paging?.hasNextPage ? lastPage.paging.page + 1 : null;
    },
    staleTime: Timestamp.fromMinutes(45).toMilliseconds(),
  });

  const hasAnyTrip = (): boolean => {
    const tripsLength = result?.data?.pages?.reduce((acc, val) => acc + (val?.trips ?? []).length, 0);
    return tripsLength > 0;
  };

  useEffect(() => {
    if (result?.isLoading || hasAnyTrip()) {
      return;
    }

    appInsights.trackAnalyticsEvent(
      getEventFactory(Event.NoTripsFound)(Feature.TripsHistoryPanel, page, previousFilters, filters)
    );
  }, [result?.data?.pages]);

  return result;
};
