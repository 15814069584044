import { Grid, GridItem, InputGroup, InputLeftElement, Input, Button } from '@chakra-ui/react';
import { translate } from '../../../../../../global/translation';
import { ReactComponent as SearchIcon } from '../../../../../../static/svg/Search.svg';
import IncorrectInformation from '../../../InfomationPanel/IncorrectInformation';
import ShareLink from '../ShareLink';
import { type Page } from '../../../../../../global/types';
import { type VesselViewModel } from '../../../../types';
import { type Timestamp } from '../../../../utils/timestamp';

interface Props {
  handleVesselSearchOpen: () => void;
  handlePickTimeOpen: () => void;
  page: Page;
  targetVessel: VesselViewModel;
  timestamp: Timestamp;
  startTimestamp: Timestamp;
  timezone: string;
}

const QuickAccessBody: React.FC<Props> = ({
  handleVesselSearchOpen,
  handlePickTimeOpen,
  page,
  targetVessel,
  startTimestamp,
  timestamp,
  timezone,
}) => {
  return (
    <Grid templateColumns="1fr 1fr">
      <GridItem colSpan={2}>
        <div className="vessel-search-trigger">
          <InputGroup>
            <InputLeftElement className="search-icon">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input type="search" placeholder={translate('INPUT_PLACEHOLDER')} onClick={handleVesselSearchOpen} />
          </InputGroup>
        </div>
      </GridItem>
      <GridItem className="feature-button-container" colSpan={2}>
        <Button className="pick-time-trigger" variant="primary" onClick={handlePickTimeOpen}>
          {translate('PICK_TIME')}
        </Button>
        <IncorrectInformation
          page={page}
          mmsi={targetVessel?.mmsi}
          shipName={targetVessel?.name}
          timestamp={timestamp}
          timezone={timezone}
        />
        <ShareLink
          timestamp={timestamp}
          startTimestamp={startTimestamp}
          targetVesselMmsi={targetVessel?.mmsi}
          page={page}
          timezone={timezone}
        />
      </GridItem>
    </Grid>
  );
};

export default QuickAccessBody;
