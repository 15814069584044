import { useMemo, type FC, useCallback, type MouseEvent } from 'react';
import { Card, CardBody, CardHeader, Text, Tooltip } from '@chakra-ui/react';
import JobCard from './JobCard';
import DefaultTripTypeIcon from '../../../../../static/img/trip-type-default.png';
import { type Trip } from '../../../../Trips/types';
import { isTugTrip } from '../../../../Trips/utils/is-tug-trip';
import { translate } from '../../../../../global/translation';
import { getEventFactory } from '../../../../../global/utils/get-event-factory';
import { Event, type Page } from '../../../../../global/types';
import { useAppInsights } from '../../../../../hooks/useAppInsights';
import { getStartTimeFromTrip } from '../../../utils/dynamicScrubbingUtils';
import { navigateOrOpenInNewTab } from '../../../../../global/utils/navigation-utils';
import { type Timestamp } from '../../../utils/timestamp';
import { type OperationViewModel } from '../../Map/types';

interface Props {
  trip: Trip;
  onJobSelected: () => void;
  onJobHover: (tripId: string, jobId: string) => void;
  onJobHoverOut: () => void;
  vesselNameSearchPhrase: string;
  setStartTimestamp: (timestamp: Timestamp) => void;
  setTimestamp: (timestamp: Timestamp) => void;
  onTargetVesselChange: (mmsi: string) => void;
  page: Page;
  operation: OperationViewModel;
}

const TripCard: FC<Props> = ({
  trip,
  onJobSelected,
  onJobHover,
  onJobHoverOut,
  vesselNameSearchPhrase,
  setStartTimestamp,
  setTimestamp,
  onTargetVesselChange,
  page,
  operation,
}) => {
  const appInsights = useAppInsights();
  const assistedVesselDetails = useMemo(() => {
    if (isTugTrip(trip)) {
      return translate('NO_VESSEL');
    }

    const assistedVesselName = trip.assistedVesselName ?? translate('UNKNOWN');
    const customerName = trip.customerName ? ` · ${trip.customerName}` : '';
    return `${assistedVesselName}${customerName}`;
  }, [trip]);

  const handleHeaderClick = useCallback(
    (e: MouseEvent) => {
      if (!isTugTrip(trip)) {
        onJobSelected();
        const { assistedVesselMmsi } = trip;
        const event = getEventFactory(Event.TripsPanelTripClicked)(
          page,
          trip.port,
          trip.tripId,
          null,
          assistedVesselMmsi.toString()
        );
        appInsights.trackAnalyticsEvent(event);
        const tripStartTime = getStartTimeFromTrip(trip);
        navigateOrOpenInNewTab(
          {
            port: operation?.key,
            mmsi: assistedVesselMmsi.toString(),
            timestamp: tripStartTime,
          },
          e,
          params => {
            const { timestamp, mmsi } = params;
            setStartTimestamp(timestamp);
            setTimestamp(timestamp);
            onTargetVesselChange(mmsi);
          }
        );
      }
    },
    [trip]
  );

  return (
    <Card className="trip-card">
      <CardHeader className="trip-header-container" onClick={handleHeaderClick}>
        <div className="trip-header">
          {!isTugTrip(trip) && <img className="trip-header-item trip-header-image" src={DefaultTripTypeIcon} alt="" />}
          <Tooltip placement="top" label={assistedVesselDetails}>
            <Text className="trip-header-item trip-header-text">{assistedVesselDetails}</Text>
          </Tooltip>
        </div>
      </CardHeader>
      <CardBody>
        {trip.jobs.length > 0 &&
          trip.jobs.map(j => (
            <JobCard
              key={j.jobId}
              trip={trip}
              job={j}
              operationKey={operation?.key}
              onSelect={onJobSelected}
              onHover={onJobHover}
              onHoverOut={onJobHoverOut}
              setStartTimestamp={setStartTimestamp}
              setTimestamp={setTimestamp}
              onTargetVesselChange={onTargetVesselChange}
              page={page}
              vesselNameSearchPhrase={vesselNameSearchPhrase}
              timezone={operation?.timezone}
            />
          ))}
      </CardBody>
    </Card>
  );
};

export default TripCard;
