import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { getJobTrackFeatures, getVesselTrackLayerWithGradient } from '../utils/layer-utils';
import { layerSources } from '../constants';
import { jobTrackLayer } from '../layers-config';
import { type Job } from '../../../../Trips/types';
import { Timestamp } from '../../../utils/timestamp';
import { isUnderOrdersActivity } from '../../../utils/isUnderOrdersActivity';
import { type BaseActivity } from '../../../types';
import { type VesselTrackViewModel } from '../types';
import type { ActivityType } from '../../../../../global/types';

interface Props {
  job: Job;
  vesselTracks: VesselTrackViewModel[];
}

const JobTracksLayer: FC<Props> = ({ job, vesselTracks }) => {
  const [features, trackLayer] = useMemo(() => {
    const activities = job?.activities?.map(a => {
      return {
        activityId: a.activityId,
        timestampRange: { from: Timestamp.fromSeconds(a.timestampStart), to: Timestamp.fromSeconds(a.timestampEnd) },
        type: a.activity as ActivityType,
        isSpeedLimited: !isUnderOrdersActivity(a.activity as ActivityType),
      } as BaseActivity;
    });
    return [
      getJobTrackFeatures(vesselTracks),
      getVesselTrackLayerWithGradient(jobTrackLayer, vesselTracks, activities),
    ];
  }, [job, vesselTracks]);

  return (
    <Source
      id={layerSources.jobTracks}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
      lineMetrics
    >
      <Layer {...trackLayer} />
    </Source>
  );
};

export default JobTracksLayer;
