import { useEffect } from 'react';
import { translate, type TranslationKey } from '../../../global/translation';
import { Mode } from '../types';
import { type OperationViewModel } from '../components/Map/types';
import { useAppInsights } from '../../../hooks/useAppInsights';
import { useMapContext } from '../../../global/state/GlobalProvider';

export const useTrackPageView = (
  mode: Mode,
  isWorldView: boolean,
  operation: OperationViewModel,
  operationKey: string
): void => {
  const appInsights = useAppInsights();
  const { settings } = useMapContext();

  const getPageTranslationKey = (): TranslationKey => {
    if (isWorldView) {
      return 'WORLD_VIEW';
    }

    if (!operation) {
      return null;
    }

    return mode === Mode.Playback ? 'PLAYBACK' : 'LIVE_VIEW';
  };

  useEffect(() => {
    const pageTranslationKey = getPageTranslationKey();
    if (!pageTranslationKey) {
      return;
    }

    appInsights.trackPageView({
      name: translate(getPageTranslationKey()),
      uri: globalThis.location.pathname,
      properties: {
        operation: operationKey,
        chartLayer: settings.layer,
        showVesselTracks: settings.showVesselTracks,
        showVesselNames: settings.showVesselNames,
        showTidalData: settings.showQuiverPlot,
      },
    });
  }, [mode, isWorldView, operation]);
};
