import { type BarTooltipProps } from '@nivo/bar';
import moment from 'moment';
import { useDetectMobileScreenWidth } from '../../../../../hooks/useDetectMobileScreenWidth';

const BarTooltip: React.FC<BarTooltipProps<any>> = ({ data }) => {
  const isMobileView = useDetectMobileScreenWidth();
  return isMobileView ? null : (
    <div className="bar-tooltip">
      <p>{moment(data.day).format('DD MMMM')}</p>
    </div>
  );
};

export default BarTooltip;
