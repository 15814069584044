import { type FC, type ChangeEvent, useState, useMemo, useRef } from 'react';
import cx from 'classnames';
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  Text,
} from '@chakra-ui/react';
import ActionButton from '../ActionButton';
import { ReactComponent as Pointer } from '../../../../../../../static/svg/Pointer.svg';
import { ReactComponent as SearchIcon } from '../../../../../../../static/svg/Search.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../../../../../static/svg/ChevronLeft.svg';
import { type VesselViewModel } from '../../../../../types';
import { translate } from '../../../../../../../global/translation';
import { useDebouncing } from '../../../../../../../hooks/useDebouncing';
import { getEventFactory } from '../../../../../../../global/utils/get-event-factory';
import { useAppInsights } from '../../../../../../../hooks/useAppInsights';
import { Timestamp } from '../../../../../utils/timestamp';
import { Event, type MmsiKey, type Page } from '../../../../../../../global/types';
import { type ModalState, ModalsEvent } from '../types';

export interface Props {
  page: Page;
  operation: string;
  timestamp: Timestamp;
  vesselData: Record<MmsiKey, VesselViewModel>;
  modalState: ModalState;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  dispatch: (event: ModalsEvent) => void;
  onTargetVesselChange: (mmsi: string) => void;
}

const VesselSearch: FC<Props> = ({
  page,
  operation,
  timestamp,
  vesselData,
  modalState,
  containerRef,
  dispatch,
  onTargetVesselChange,
}): JSX.Element => {
  const [searchPhrase, setSearchPhrase] = useState<string>(null);
  const inputRef = useRef<HTMLInputElement>();
  const appInsights = useAppInsights();

  const sendDebouncedResultsEvent = useDebouncing((data: { searchPhrase: string; resultsQuantity: number }) => {
    const eventType = data.resultsQuantity > 0 ? Event.VesselSearchGotResults : Event.VesselSearchGotNoResults;
    const event = getEventFactory(eventType)(page, operation, timestamp, data.searchPhrase, data.resultsQuantity);
    appInsights.trackAnalyticsEvent(event);
  }, Timestamp.fromMilliseconds(700));

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchPhrase(event.target.value);
  };

  const resetInput = (): void => {
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
      setSearchPhrase('');
    }
  };

  const selectVessel = (vessel: VesselViewModel): void => {
    const event = getEventFactory(Event.VesselFoundAndSelected)(
      page,
      operation,
      timestamp,
      searchPhrase,
      vessel.mmsi,
      vessel.name
    );
    appInsights.trackAnalyticsEvent(event);
    onTargetVesselChange(vessel.mmsi);
    resetInput();
    dispatch(ModalsEvent.CloseVesselSearch);
  };

  const containsPhrase = (
    phrase: string,
    vessel: VesselViewModel,
    selector: (v: VesselViewModel) => string
  ): boolean => {
    return !!selector(vessel) && selector(vessel).toLowerCase().includes(phrase.toLowerCase());
  };

  const isSearchPhraseProvided = useMemo(() => {
    return !!searchPhrase && !!searchPhrase.length && searchPhrase !== '';
  }, [searchPhrase]);

  const vesselSearchModels = useMemo(() => vesselData && Object.entries(vesselData).map(([, v]) => v), [vesselData]);

  const searchResults = useMemo(() => {
    if (!isSearchPhraseProvided) {
      return [];
    }

    const results = vesselSearchModels.filter(
      v => containsPhrase(searchPhrase, v, v => v.name) || containsPhrase(searchPhrase, v, v => v.mmsi)
    );
    sendDebouncedResultsEvent({ searchPhrase, resultsQuantity: results.length });

    return results;
  }, [searchPhrase, isSearchPhraseProvided, vesselSearchModels]);

  const classes = useMemo(() => {
    return {
      startTyping: cx({
        hidden: isSearchPhraseProvided,
      }),
      noResults: cx({
        hidden: searchResults.length > 0 || !isSearchPhraseProvided,
      }),
      results: cx({
        hidden: searchResults.length === 0,
      }),
    };
  }, [isSearchPhraseProvided, searchResults]);

  const handleCloseModal = (): void => {
    resetInput();
    const event = getEventFactory(Event.VesselSearchClosed)(page, operation, timestamp);
    appInsights.trackAnalyticsEvent(event);
    dispatch(ModalsEvent.CloseVesselSearch);
  };

  const handleBack = (): void => {
    const event = getEventFactory(Event.BackFromVesselSearchClicked)(page, operation, timestamp);
    appInsights.trackAnalyticsEvent(event);
    dispatch(ModalsEvent.BackFromVesselSearch);
  };

  return (
    <Modal
      isOpen={modalState.isOpen}
      onClose={handleCloseModal}
      initialFocusRef={inputRef}
      finalFocusRef={containerRef}
    >
      <ModalContent className="vessel-search">
        <ModalBody>
          <ActionButton icon={<ChevronLeftIcon />} labelKey="BACK" onClick={handleBack} />
          <InputGroup className="vessel-search-bar focused">
            <InputLeftElement className="search-icon">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              ref={inputRef}
              type="search"
              placeholder={translate('INPUT_PLACEHOLDER')}
              onChange={handleTextInput}
            />
          </InputGroup>
          <div className="custom-scroll-bar">
            <div className={classes.startTyping}>
              <Text className="message-header">{translate('START_TYPING')}</Text>
              <Text className="message-subheader">{translate('TRY_SEARCH_VESSEL')}</Text>
            </div>
            <div className={classes.noResults}>
              <Text className="message-header">{translate('NO_VESSEL_FOUND')}</Text>
              <Text className="message-subheader">{translate('TRY_SEARCH_ANOTHER_VESSEL')}</Text>
            </div>
            <div className={classes.results}>
              <Text>{translate('JUMP_TO')}</Text>
              <List className="filter-suggestion-list">
                {searchResults.map(vessel => (
                  <ListItem key={vessel.mmsi}>
                    <Button
                      leftIcon={<Pointer />}
                      onClick={e => {
                        selectVessel(vessel);
                        e.stopPropagation();
                      }}
                    >
                      {vessel.name}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VesselSearch;
