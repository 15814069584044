import { useMemo } from 'react';
import { usePollLiveData } from './usePollLiveData';
import { useFetchPlaybackData } from './useFetchPlaybackData';
import { usePrefetchPlaybackData } from './usePrefetchPlaybackData';
import { type MapData, Mode, type VesselPosition, type VesselViewModel } from '../types';
import { type Timestamp } from '../utils/timestamp';

export const useMapData = (operationKey: string, mode: Mode, timestamp: Timestamp): MapData => {
  const liveData = usePollLiveData(operationKey, mode);
  const queryResult = useFetchPlaybackData(timestamp, operationKey, mode);
  usePrefetchPlaybackData(timestamp, operationKey, queryResult, mode);
  const { data: playbackModel, isError, isLoading: isPlaybackDataLoading } = queryResult;

  const vesselData = useMemo((): Record<string, VesselViewModel> => {
    return (mode === Mode.Liveview ? liveData?.vesselData : playbackModel?.vesselData) ?? {};
  }, [mode, playbackModel, liveData]);

  const vesselPositions = useMemo((): VesselPosition[] => {
    if (mode === Mode.Liveview) {
      return liveData?.vesselPositions;
    }

    const playbackPositions = playbackModel?.vesselPositions;
    return playbackPositions ? playbackPositions[timestamp.toSeconds()] : null;
  }, [mode, playbackModel?.vesselPositions, liveData, timestamp]);

  return { liveData, vesselPositions, vesselData, isPlaybackDataLoading, isError };
};
