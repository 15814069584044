import { useMemo, type FC } from 'react';
import cx from 'classnames';
import { Box } from '@chakra-ui/react';
import { type LiveDataViewModel, Mode } from '../../types';
import OperationSelector from '../OperationSelector';
import { type OperationViewModel } from '../Map/types';
import indicateOperationStatus from '../../utils/indicateOperationStatus';
import TripsHistoryToggle from '../TripsHistoryToggle';

interface Props {
  mode: Mode;
  isTripsPanelOpened: boolean;
  operation: OperationViewModel;
  operationKey: string;
  liveData: LiveDataViewModel;
  isWorldView: boolean;
  onOperationSelect: (operationKey: string) => void;
  toggleTripsPanel: () => void;
}

export const LeftSideControls: FC<Props> = ({
  mode,
  isTripsPanelOpened,
  operation,
  operationKey,
  liveData,
  isWorldView,
  onOperationSelect,
  toggleTripsPanel,
}) => {
  const operationStatus = useMemo(() => {
    return indicateOperationStatus(liveData);
  }, [liveData?.vesselPositions]);

  return (
    <Box className={cx('map-left-controls-container', { expanded: isTripsPanelOpened }, 'top-left-functions')}>
      {!isTripsPanelOpened && !isWorldView && <TripsHistoryToggle toggleTripsPanel={toggleTripsPanel} />}
      {mode === Mode.Liveview && (
        <OperationSelector
          operation={operation?.name ?? operationKey}
          operationStatus={operationStatus}
          onOperationSelect={onOperationSelect}
          isWorldView={isWorldView}
          isTripsPanelOpened={isTripsPanelOpened}
        />
      )}
    </Box>
  );
};
