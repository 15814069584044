import { useCallback, useMemo, useState } from 'react';
import { approximateTimestamp } from '../utils/approximateTimestamp';
import { Timestamp } from '../utils/timestamp';
import { Mode, type TimestampHandler } from '../types';

export const useManageTimestamps = (timestamp: string, mode: Mode): TimestampHandler => {
  const [playbackTimestamp, setPlaybackTimestamp] = useState(
    approximateTimestamp(Timestamp.fromSeconds(Number(timestamp)))
  );

  const handleSetPlaybackTimestamp = useCallback((timestamp?: Timestamp): void => {
    setPlaybackTimestamp(timestamp ? approximateTimestamp(timestamp) : null);
  }, []);

  const currentTimestamp = useMemo((): Timestamp => {
    return mode === Mode.Liveview || !playbackTimestamp ? Timestamp.now() : playbackTimestamp;
  }, [playbackTimestamp, mode]);

  return { playbackTimestamp, currentTimestamp, handleSetPlaybackTimestamp };
};
