import { useEffect, useState, type FC, type JSX, useCallback } from 'react';
import { Box, Collapse, Flex, Link, Text, VStack } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { DatePicker } from 'rsuite';
import { first } from 'lodash';
import VesselNameSearchBar from './VesselNameSearchBar';
import JobTypeSelector from './JobTypeSelector';
import TripTypeDropDown from './TripTypeDropDown';
import { useTripsContext } from '../../../../../global/state/GlobalProvider';
import { ReactComponent as ChevronUpIcon } from '../../../../../static/svg/UpArrow.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../../static/svg/DownArrow.svg';
import { ReactComponent as CalendarIcon } from '../../../../../static/svg/CalendarIcon.svg';
import { defaultFilters } from '../../../../Trips/utils/initial-trips-state';
import { type Page } from '../../../../../global/types';
import { translate } from '../../../../../global/translation';

const defaultDatePickerDate = null;

interface Props {
  displayMoreFilters: boolean;
  toggleMoreFilters: () => void;
  onClose: () => void;
  page: Page;
  selectedDate: Date;
  setDateFilter: (date: Date) => void;
  resetFilters: () => void;
}

const TripsPanelHeaderSection: FC<Props> = ({
  displayMoreFilters,
  toggleMoreFilters,
  onClose,
  page,
  selectedDate,
  setDateFilter,
  resetFilters,
}) => {
  const { filters } = useTripsContext();
  const [datePickerDate, setDatePickerDate] = useState<Date>(defaultDatePickerDate);

  const anyFilterApplied =
    first(filters.tripType) !== first(defaultFilters.tripType) ||
    filters.jobType !== defaultFilters.jobType ||
    filters.vesselName !== defaultFilters.vesselName ||
    !!selectedDate;

  const renderCalendarIcon = useCallback((): JSX.Element => {
    return <CalendarIcon />;
  }, []);

  const isExpanded = (): boolean => {
    return displayMoreFilters;
  };

  const renderChevronIcon = (): JSX.Element => {
    return isExpanded() ? <ChevronUpIcon /> : <ChevronDownIcon />;
  };

  const shouldRenderDate = useCallback((date: Date): boolean => date.valueOf() >= new Date().setHours(0, 0, 0, 0), []);

  useEffect(() => {
    if (selectedDate) {
      setDatePickerDate(selectedDate);
    } else {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setDatePickerDate(yesterday);
    }
  }, [selectedDate]);

  return (
    <VStack className="trips-panel-header-container">
      <Flex className="header-section" justifyContent="space-between">
        <Text className="text-header" alignContent="center">
          {translate('TRIPS_HISTORY')}
        </Text>
        <CloseIcon style={{ cursor: 'pointer', alignSelf: 'center' }} onClick={onClose} />
      </Flex>
      <Flex className="header-section" justifyContent="space-between">
        <VesselNameSearchBar page={page} />
        <Box marginLeft="10px">
          <DatePicker
            className="pick-time-date-picker"
            menuClassName="pick-time-date-picker-menu"
            placement="bottomEnd"
            format="yyyy-MM-dd"
            shouldDisableDate={shouldRenderDate}
            editable={false}
            cleanable={false}
            value={datePickerDate}
            caretAs={renderCalendarIcon}
            onChange={setDateFilter}
            oneTap
          />
        </Box>
      </Flex>
      <Box className="more-filters-container">
        <Flex justifyContent="flex-end">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={toggleMoreFilters}>
            {renderChevronIcon()}
            {translate('FILTERS')}
          </Link>
          {anyFilterApplied ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link onClick={resetFilters} marginLeft="20px">
              {translate('CLEAR_ALL')}
            </Link>
          ) : (
            <></>
          )}
        </Flex>
        <Collapse in={displayMoreFilters} animateOpacity>
          <Box width="100%">
            <JobTypeSelector page={page} />
            <TripTypeDropDown page={page} />
          </Box>
        </Collapse>
      </Box>
    </VStack>
  );
};

export default TripsPanelHeaderSection;
