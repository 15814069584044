import React, { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, Spinner, Text, useBoolean } from '@chakra-ui/react';
import { forEach, groupBy } from 'lodash';
import cx from 'classnames';
import TripsPanelHeaderSection from './components/TripsPanelHeaderSection';
import TripCard from './components/TripCard';
import NoTripsFound from './components/NoTripsFound';
import { defaultTripsPageNumber, quantityOfTripsPerRequest } from './constants';
import { useDispatch, useTripsContext } from '../../../../global/state/GlobalProvider';
import { type TripJobPair, TripsPanelState, type Trip, type TripGroup } from '../../../Trips/types';
import { Timestamp } from '../../utils/timestamp';
import { useDetectScrolledToBottom } from '../../hooks/useDetectScrolledToBottom';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import { Event, Feature, type Page } from '../../../../global/types';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { useGenericQuery } from '../../../Trips/hooks/useGenericQuery';
import { ActionType } from '../../../../global/state/reducer';
import { useTripsInfiniteQuery } from '../../hooks/useTripsInfiniteQuery';
import BarChartDatePicker from '../BarChartDatePicker';
import { initStateFilters } from '../../../Trips/utils/initial-trips-state';
import { getLocalTimestampFromDate } from '../../../../global/utils/date-utils';
import { useDetectMobileScreenWidth } from '../../../../hooks/useDetectMobileScreenWidth';
import { useEcoRatingTooltip } from '../../hooks/useEcoRatingTooltip';
import { type OperationViewModel } from '../Map/types';

const defaultSelectedDateValue = null;

export interface Props {
  isOpen: boolean;
  page: Page;
  onClose: () => void;
  setStartTimestamp: (timestamp: Timestamp) => void;
  setTimestamp: (timestamp: Timestamp) => void;
  onTargetVesselChange: (mmsi: string) => void;
  setHoveredJob: (tripJobPair: TripJobPair) => void;
  operation: OperationViewModel;
}

const TripsPanel: FC<Props> = ({
  isOpen,
  page,
  onClose,
  setStartTimestamp,
  setTimestamp,
  onTargetVesselChange,
  setHoveredJob,
  operation,
}) => {
  const [displayMoreFilters, setDisplayMoreFilters] = useBoolean();
  const { filters, tripsPanelState } = useTripsContext();
  const tripsDispatch = useDispatch();
  const appInsights = useAppInsights();
  const timeoutId = useRef<NodeJS.Timeout>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(defaultSelectedDateValue);
  const isMobileView = useDetectMobileScreenWidth();

  const scrollRef = useRef<HTMLDivElement>();
  const { isBottom } = useDetectScrolledToBottom(scrollRef);
  useGenericQuery({
    actionType: ActionType.SET_VESSELNAMES,
    endpoint: `/trips/vessel-names/`,
    queryKey: 'useVesselNames',
  });

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useTripsInfiniteQuery(
    filters,
    quantityOfTripsPerRequest,
    defaultTripsPageNumber,
    page
  );

  useEffect(() => {
    if (isBottom && hasNextPage && !isFetching && trips.length) {
      const event = getEventFactory(Event.TripsPanelScrolledToEnd)(page, filters.port);
      appInsights.trackAnalyticsEvent(event);
      void fetchNextPage();
    }
  }, [isBottom]);

  const trips = useMemo(() => {
    return (data?.pages ?? []).reduce<Trip[]>((result, current) => {
      return [...result, ...current.trips];
    }, []);
  }, [data]);

  useEffect(() => {
    if (isFetchingNextPage) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isFetchingNextPage]);

  useEffect(() => {
    setSelectedDate(null);
  }, [operation]);

  const setDateFilter = useCallback(
    (date: Date): void => {
      if (date) {
        date.setHours(0, 0, 0);
        const startDate = getLocalTimestampFromDate(date, operation?.timezone).toDate().toISOString();
        date.setHours(23, 59, 59);
        const endDate = getLocalTimestampFromDate(date, operation?.timezone).toDate().toISOString();
        const newFilter = {
          ...filters,
          startDate,
          endDate,
        };
        tripsDispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
        setSelectedDate(date);
        appInsights.trackAnalyticsEvent(
          getEventFactory(Event.FilteredDate)(Feature.TripsHistoryPanel, page, filters, newFilter, startDate)
        );
      } else {
        const newFilter = {
          ...filters,
          startDate: initStateFilters.startDate,
          endDate: initStateFilters.endDate,
        };
        tripsDispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
        setSelectedDate(null);
        appInsights.trackAnalyticsEvent(
          getEventFactory(Event.FilteredDate)(Feature.TripsHistoryPanel, page, filters, newFilter, newFilter.startDate)
        );
      }
    },
    [operation, tripsDispatch, appInsights, filters]
  );

  const resetFilters = useCallback((): void => {
    setSelectedDate(null);
    tripsDispatch({ type: ActionType.RESET_FILTER });
    const event = getEventFactory(Event.FiltersReset)(Feature.TripsHistoryPanel, page, filters);
    appInsights.trackAnalyticsEvent(event);
  }, []);

  const onJobHovered = useCallback(
    (tripId: string, jobId: string): void => {
      const trip = trips.find(t => t.tripId === tripId);
      const job = trip?.jobs?.find(j => j.jobId === jobId);

      if (!job) {
        return;
      }

      setHoveredJob({ job, trip });
    },
    [timeoutId.current, trips]
  );

  const onJobHoverOut = useCallback((): void => {
    clearTimeout(timeoutId.current);
    timeoutId.current = null;
    setHoveredJob(null);
  }, [timeoutId.current]);

  const ecoRatingTooltip = useEcoRatingTooltip(tripsPanelState === TripsPanelState.Open);

  const handleCloseTripsPanel = useCallback((): void => {
    onClose();
    ecoRatingTooltip.onClose();
  }, []);

  const onJobSelected = useCallback((): void => {
    setDisplayMoreFilters.off();
    if (isMobileView) {
      handleCloseTripsPanel();
    }
  }, [isMobileView]);

  const tripsNodes = useMemo(() => {
    const dictionary = groupBy(trips, t =>
      Timestamp.fromSeconds(t.timestampStart).toLocationDateTimeOffset(operation?.timezone).format('ddd MMM DD YYYY')
    );
    const groupedTrips: TripGroup[] = [];
    forEach(dictionary, (trips, date) => groupedTrips.push({ date, trips }));

    return groupedTrips.map(group => (
      <div key={group.date}>
        <Text as="p" className="trip-group-header">
          {group.date}
        </Text>
        {group.trips.length > 0 &&
          group.trips.map(trip => (
            <TripCard
              key={trip.id}
              trip={trip}
              onJobSelected={onJobSelected}
              onJobHover={onJobHovered}
              onJobHoverOut={onJobHoverOut}
              vesselNameSearchPhrase={filters.vesselName}
              setStartTimestamp={setStartTimestamp}
              setTimestamp={setTimestamp}
              onTargetVesselChange={onTargetVesselChange}
              page={page}
              operation={operation}
            />
          ))}
      </div>
    ));
  }, [trips]);

  const renderTripsPanelJobsContent = (): JSX.Element => {
    if (isFetching && !isFetchingNextPage) {
      return (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      );
    }

    if (tripsNodes.length) {
      return (
        <>
          {tripsNodes}
          <Flex className="bottom-spinner" justifyContent="center" hidden={!isFetchingNextPage}>
            <Spinner />
          </Flex>
        </>
      );
    }

    return <NoTripsFound page={page} />;
  };

  return (
    <Flex className={cx('trips-panel-container', { expanded: isOpen })} flexDirection="column">
      {isOpen && (
        <>
          <TripsPanelHeaderSection
            onClose={handleCloseTripsPanel}
            displayMoreFilters={displayMoreFilters}
            toggleMoreFilters={setDisplayMoreFilters.toggle}
            page={page}
            selectedDate={selectedDate}
            setDateFilter={setDateFilter}
            resetFilters={resetFilters}
          />
          <Box className="trips-panel-jobs-list-container" ref={scrollRef} onScroll={setDisplayMoreFilters.off}>
            <BarChartDatePicker
              setDateFilter={setDateFilter}
              selectedDate={selectedDate}
              height={150}
              operation={operation}
              ecoRatingTooltip={ecoRatingTooltip}
            />
            {renderTripsPanelJobsContent()}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default React.memo(TripsPanel);
