import { useMemo } from 'react';
import { type FC } from 'react';
import { Image, useImage } from '@chakra-ui/react';
import Tug from '../../../../../static/img/tug.png';
import { CONFIG_CODES } from '../../../../../global/types';
import { getConfig } from '../../../../../global/utils/getConfig';

interface Props {
  imo: number;
}

const JobCardVesselImage: FC<Props> = ({ imo }) => {
  const imageUrl = useMemo(() => {
    const apiHost = getConfig(CONFIG_CODES.REACT_APP_API_HOST);
    return imo ? `${apiHost}/images/${imo}.jpg` : null;
  }, [imo]);

  const status = useImage({ src: imageUrl, onError: () => {} });

  const isImageLoaded = status === 'loaded';

  return imageUrl && isImageLoaded ? <Image src={imageUrl} /> : <Image src={Tug} />;
};

export default JobCardVesselImage;
