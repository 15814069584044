import { type FunctionComponent, useMemo } from 'react';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import { type Timestamp } from '../../utils/timestamp';
import { formatSpeed, getSpeedText, getTextForSpeedToolTip } from '../../utils/speed-utils';
import { translate, translateAndFormat } from '../../../../global/translation';
import type { VesselViewModel } from '../../types';
import type { Speed } from '../../../../global/types';

interface Props {
  timestamp: Timestamp;
  vessel: VesselViewModel;
  speed: Speed;
  timezone: string;
}

const ScrubberHeaderSection: FunctionComponent<Props> = ({ timestamp, vessel, speed, timezone }) => {
  const clockData = useMemo(() => {
    return timestamp?.getClockInformation(timezone);
  }, [timestamp, timezone]);

  const formattedSpeed = formatSpeed(speed);
  const speedType = getSpeedText(speed);
  const tooltipText = useMemo(() => getTextForSpeedToolTip(speed), [speed]);
  const mmsiHeader = useMemo(() => {
    return vessel?.mmsi ? translateAndFormat('MMSI_VALUE', vessel.mmsi) : '';
  }, [vessel]);

  return (
    <div className="scrubber-header-container">
      <div className="header-value">
        <Text className="header-label-text text-xs-regular">{mmsiHeader}</Text>
        <Text className="text-sm-regular text-overflow">{vessel?.name}</Text>
      </div>
      <div className="header-value">
        <Text className="header-label-text text-xs-regular">{`${translate('LOCAL_TIME')} ${clockData?.timezone}`}</Text>
        <div>
          <Text as="span" className="text-sm-regular date-text">
            {clockData?.dateString}
          </Text>
          <Text as="span" className="text-sm-regular">
            {clockData?.time}
          </Text>
        </div>
      </div>
      <div className="header-value">
        <Tooltip className="tooltip" placement="top" label={tooltipText}>
          <Box>
            <Text className="header-label-text text-xs-regular">{speedType}</Text>
            <Text className="text-sm-regular">{formattedSpeed}</Text>
          </Box>
        </Tooltip>
      </div>
    </div>
  );
};

export default ScrubberHeaderSection;
