import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { type BarDatum, type ComputedDatum } from '@nivo/bar/dist/types/types';
import moment from 'moment';
import { maxBy, minBy } from 'lodash';
import { Box } from '@chakra-ui/react';
import { type Datum } from '@nivo/line';
import BarChartAxisBottomTicks from './BarChartAxisBottomTicks';
import BarChartAxisRightTicks from './BarChartAxisRigthTicks';
import BarTooltip from './BarTooltip';
import { type DateRange } from '../../../../../global/types';
import { mapEcoRatingsToBarChartData, getPlaceholderBarChartData, getEcoRatingColor } from '../utils/bar-chart-utils';
import {
  barChartAdjustmentValue,
  barChartDateFormat,
  co2SurplusKey,
  defaultBarChartMaxValue,
  defaultBarChartRangeSize,
  ecoRatingKey,
} from '../constants';
import { type DailyEcoRating } from '../../../../Trips/types';
import { type BarChartData } from '../types';
import colors from '../../../../../theme/colors';

interface Props {
  id: string | number;
  height: number;
  ecoRatings: DailyEcoRating[];
  dateRange: DateRange;
  selectedDate: Date;
  setDateFilter: (date: Date) => void;
  barChartRangeSize: number;
}

const barBorderWidth = 2.5;
const smallRangeBarBorderRadius = 5;
const largeRangeBarBorderRadius = 4;
const opacityMap: Record<number, string> = {
  1: 'FF',
  0.5: '7F',
  0.3: '4C',
};

const BarChart: FC<Props> = ({ id, height, ecoRatings, dateRange, selectedDate, setDateFilter, barChartRangeSize }) => {
  const [data, setData] = useState(
    mapEcoRatingsToBarChartData(ecoRatings?.length ? ecoRatings : getPlaceholderBarChartData(dateRange))
  );

  useEffect(() => {
    let newData: BarChartData[];
    if (ecoRatings?.length) {
      newData = mapEcoRatingsToBarChartData(ecoRatings);
    } else {
      newData = mapEcoRatingsToBarChartData(getPlaceholderBarChartData(dateRange));
    }
    setData(newData);
  }, [ecoRatings]);

  const { minValue, maxValue } = useMemo(() => {
    const minValue = minBy(data, d => d.estimatedCo2Surplus).estimatedCo2Surplus - barChartAdjustmentValue;
    const maxValue = maxBy(data, d => d.ecoRating).ecoRating + barChartAdjustmentValue;
    return {
      minValue,
      maxValue: Math.max(maxValue, defaultBarChartMaxValue),
    };
  }, [data]);

  const barItemClickHandler = useCallback(
    (barItem: ComputedDatum<BarDatum>) => {
      const selectedDateString = selectedDate && moment(selectedDate).format(barChartDateFormat);

      if (selectedDateString && barItem.data.day === selectedDateString) {
        setDateFilter(null);
      } else {
        setDateFilter(moment(barItem.indexValue).toDate());
      }
    },
    [selectedDate, setDateFilter]
  );

  const getHexOpacity = (data: BarDatum): string => {
    const selectedDateString = selectedDate && moment(selectedDate).format(barChartDateFormat);
    if (selectedDateString) {
      if (data.day === selectedDateString) {
        return opacityMap[1];
      }
      return opacityMap[0.3];
    }

    if (data.isEmpty) {
      return opacityMap[0.5];
    }

    return opacityMap[1];
  };

  const getColor = (data: ComputedDatum<BarDatum>): string => {
    const defaultColor = `${colors.gray[700]}${getHexOpacity(data.data)}`;
    if (data.data.isEmpty) {
      return defaultColor;
    }

    return data.id === ecoRatingKey ? `${getEcoRatingColor(data.value)}${getHexOpacity(data.data)}` : defaultColor;
  };

  const getBorderColor = (datum: Datum): string => {
    const defaultColor = colors.gray[700];
    return `${defaultColor}${getHexOpacity(datum.data.data)}`;
  };

  return (
    <Box id={`bar-${id}`} className="bar-chart-container" height={height}>
      <ResponsiveBar
        data={data as any[]}
        keys={[ecoRatingKey, co2SurplusKey]}
        onClick={barItemClickHandler}
        minValue={minValue}
        maxValue={maxValue}
        indexBy="id"
        margin={{ left: 20, right: 30, bottom: 40, top: 10 }}
        padding={0.4}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getColor}
        borderWidth={barBorderWidth}
        borderColor={getBorderColor}
        borderRadius={
          barChartRangeSize === defaultBarChartRangeSize ? smallRangeBarBorderRadius : largeRangeBarBorderRadius
        }
        axisRight={{
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          truncateTickAt: 0,
          tickValues: [0, 50, 100],
          renderTick: BarChartAxisRightTicks,
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          truncateTickAt: 0,
          renderTick: BarChartAxisBottomTicks,
        }}
        axisLeft={null}
        enableLabel={false}
        gridYValues={[0, 25, 50, 75, 100]}
        role="application"
        layout="vertical"
        tooltip={BarTooltip}
      />
    </Box>
  );
};

export default BarChart;
