import { useCallback } from 'react';
import { AppActionType } from '../../../global/components/app-reducer';
import { Mode, type ModeSwitchHandler } from '../types';
import { TripsPanelState, type TripsPanelStateManager } from '../../Trips/types';
import { useAppDispatch } from '../../../global/components/AppContextProvider';
import { type Timestamp } from '../utils/timestamp';

export const useModeSwitches = (
  tripsPanelState: TripsPanelState | undefined,
  tripsPanelStateManager: TripsPanelStateManager,
  isMobileView: boolean,
  handleSetTimestamp: (timestamp?: Timestamp) => void
): ModeSwitchHandler => {
  const appDispatch = useAppDispatch();

  const switchToLiveview = useCallback((): void => {
    appDispatch({
      type: AppActionType.SET_MODE,
      payload: {
        mode: Mode.Liveview,
      },
    });
    handleSetTimestamp();

    if (tripsPanelState === TripsPanelState.Reopen) {
      tripsPanelStateManager.open();
    }
  }, [tripsPanelState, tripsPanelStateManager.open]);

  const switchToPlayback = useCallback((): void => {
    appDispatch({
      type: AppActionType.SET_MODE,
      payload: {
        mode: Mode.Playback,
      },
    });

    if (isMobileView) {
      tripsPanelStateManager.closeToReopen();
    }
  }, [isMobileView, tripsPanelStateManager.closeToReopen]);

  return { switchToLiveview, switchToPlayback };
};
